let leetCodeOrder = [
  "intro",
  "1",
  "2",
  "3",
  "6",
  "7",
  "9",
  "13",
  "14",
  "15",
  "16",
  "19",
  "21",
  "24",
  "27",
  "35",
  "53",
  "58",
  "66",
  "67",
  "70",
  "77",
  "83",
  "88",
  "96",
  "100",
  "101",
  "104",
  "111",
  "112",
  "136",
  "152",
  "162",
  "171",
  "198",
  "216",
  "219",
  "242",
  "279",
  "389",
  "458",
  "507",
  "520",
  "674",
  "704",
  "771",
  "884",
  "938",
]

leetCodeOrder = leetCodeOrder.map(item => `/algorithm/${item}/`)

export default { 画解算法: leetCodeOrder }
